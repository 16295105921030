import React from 'react';
import { Shield, Code, Lock } from 'lucide-react';
import { FeatureCard } from '../../ui/FeatureCard';

export function HeroFeatures() {
  const features = [
    {
      icon: <Shield className="w-8 h-8 text-[#E03636]" />,
      title: 'Advanced Protection',
      description: 'State-of-the-art security solutions'
    },
    {
      icon: <Code className="w-8 h-8 text-[#E03636]" />,
      title: 'Custom Development',
      description: 'Tailored software solutions'
    },
    {
      icon: <Lock className="w-8 h-8 text-[#E03636]" />,
      title: '24/7 Monitoring',
      description: 'Continuous security oversight'
    }
  ];

  return (
    <div className="relative border-t border-[#E03636]/20">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {features.map((feature, index) => (
            <FeatureCard key={index} {...feature} />
          ))}
        </div>
      </div>
    </div>
  );
}