import React from 'react';
import { Button } from '../../ui/Button';

interface HeroContentProps {
  onPageChange?: (page: string) => void;
}

export function HeroContent({ onPageChange }: HeroContentProps) {
  const handleNavigation = (page: string) => {
    if (onPageChange) {
      onPageChange(page);
    }
  };

  return (
    <div className="relative z-10">
      <div className="absolute -left-4 -top-4 w-20 h-20 bg-[#E03636] rounded-full filter blur-3xl opacity-20"></div>
      <h1 className="text-4xl md:text-6xl font-bold text-white mb-6">
        Advanced Security <br />
        <span className="text-[#E03636]">For The Digital Age</span>
      </h1>
      <p className="text-gray-400 text-lg mb-8">
        Cutting-edge cybersecurity and software solutions to protect and empower your digital infrastructure.
      </p>
      <div className="flex space-x-4">
        <Button onClick={() => handleNavigation('solutions')}>
          Explore Solutions
        </Button>
        <Button variant="outline" onClick={() => handleNavigation('contact')}>
          Contact Us
        </Button>
      </div>
    </div>
  );
}