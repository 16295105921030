import React from 'react';
import { Facebook, Twitter, Linkedin, Github, Instagram } from 'lucide-react';

export function SocialLinks() {
  const socialLinks = [
    {
      name: 'LinkedIn',
      url: 'https://linkedin.com/company/cerberus-solutions',
      icon: <Linkedin className="w-5 h-5" />,
    },
    {
      name: 'Twitter',
      url: 'https://twitter.com/cerberus_sec',
      icon: <Twitter className="w-5 h-5" />,
    },
    {
      name: 'Facebook',
      url: 'https://facebook.com/cerberussolutions',
      icon: <Facebook className="w-5 h-5" />,
    },
    {
      name: 'GitHub',
      url: 'https://github.com/cerberus-security',
      icon: <Github className="w-5 h-5" />,
    },
    {
      name: 'Instagram',
      url: 'https://instagram.com/cerberus.security',
      icon: <Instagram className="w-5 h-5" />,
    },
  ];

  return (
    <div className="flex space-x-6">
      {socialLinks.map((link) => (
        <a
          key={link.name}
          href={link.url}
          target="_blank"
          rel="noopener noreferrer"
          className="text-gray-400 hover:text-[#E03636] transition-colors duration-300 transform hover:scale-110"
          aria-label={`Follow us on ${link.name}`}
        >
          {link.icon}
        </a>
      ))}
    </div>
  );
}