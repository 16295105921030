import React from 'react';
import { Menu, X } from 'lucide-react';
import { NavLink } from '../ui/NavLink';
import { Logo } from '../ui/Logo';
import { MobileMenu } from './MobileMenu';

interface NavbarProps {
  onPageChange: (page: string) => void;
  currentPage: string;
}

export function Navbar({ onPageChange, currentPage }: NavbarProps) {
  const [isOpen, setIsOpen] = React.useState(false);

  const navItems = [
    { href: '#home', label: 'Home', page: 'home' },
    { href: '#solutions', label: 'Solutions', page: 'solutions' },
    { href: '#products', label: 'Products', page: 'products' },
    { href: '#downloads', label: 'Downloads', page: 'downloads' },
    { href: '#contact', label: 'Contact', page: 'contact' }
  ];

  const handleNavClick = (page: string, e: React.MouseEvent) => {
    e.preventDefault();
    onPageChange(page);
    setIsOpen(false);
  };

  return (
    <nav className="fixed w-full bg-[#212121]/90 backdrop-blur-sm text-white z-50 border-b border-[#E03636]/20">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-20">
          <div onClick={(e) => handleNavClick('home', e)} className="cursor-pointer">
            <Logo />
          </div>
          <div className="hidden md:block">
            <div className="ml-10 flex items-baseline space-x-8">
              {navItems.map((item) => (
                <NavLink 
                  key={item.href} 
                  href={item.href}
                  onClick={(e) => handleNavClick(item.page, e)}
                  active={currentPage === item.page}
                >
                  {item.label}
                </NavLink>
              ))}
            </div>
          </div>
          <button
            onClick={() => setIsOpen(!isOpen)}
            className="md:hidden inline-flex items-center justify-center p-2 rounded-md text-white hover:text-[#E03636] focus:outline-none"
          >
            {isOpen ? <X className="h-6 w-6" /> : <Menu className="h-6 w-6" />}
          </button>
        </div>
      </div>
      
      {isOpen && <MobileMenu items={navItems} onNavClick={handleNavClick} currentPage={currentPage} />}
    </nav>
  );
}