import React from 'react';
import { SocialLinks } from './social/SocialLinks';

export function Footer() {
  return (
    <footer className="bg-black py-12 border-t border-[#E03636]/20">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 items-center">
          <div>
            <img 
              src="/cerberus-logo-top.svg" 
              alt="Cerberus Solutions" 
              className="h-12 w-auto" 
            />
          </div>
          
          <div className="text-center">
            <SocialLinks />
          </div>
          
          <div className="text-right">
            <div className="text-gray-400 text-sm">
              © {new Date().getFullYear()} Cerberus Solutions
              <br />
              All rights reserved.
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}