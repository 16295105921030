import React from 'react';
import { HeroContent } from './HeroContent';
import { HeroImage } from './HeroImage';
import { HeroFeatures } from './HeroFeatures';
import { ShareButtons } from '../../social/ShareButtons';

interface HeroProps {
  onPageChange?: (page: string) => void;
}

export function Hero({ onPageChange }: HeroProps) {
  const shareUrl = 'https://cerberus.demonweb.net';
  const shareTitle = 'Cerberus Solutions | Network Security Specialists';
  const shareDescription = 'Leading provider of enterprise-grade network security solutions and cybersecurity services.';

  return (
    <div id="home" className="relative bg-[#212121] pt-20">
      <div className="absolute inset-0 bg-grid-pattern opacity-5"></div>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-24">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-12 items-center">
          <HeroContent onPageChange={onPageChange} />
          <HeroImage />
        </div>
        <div className="flex justify-center mt-12">
          <ShareButtons 
            url={shareUrl}
            title={shareTitle}
            description={shareDescription}
          />
        </div>
      </div>
      <HeroFeatures />
    </div>
  );
}