import React from 'react';
import { Shield, Lock, Server, Cloud, Code2, Database } from 'lucide-react';
import { SEO } from '../components/SEO';
import { ShareButtons } from '../components/social/ShareButtons';

const solutions = [
  {
    icon: <Shield className="w-16 h-16 text-[#E03636]" />,
    title: 'Threat Detection & Prevention',
    description: 'Advanced AI-powered threat detection system that identifies and neutralizes potential security risks before they become threats.',
    features: ['Real-time monitoring', 'AI-powered analysis', 'Automated response', '24/7 protection']
  },
  {
    icon: <Lock className="w-16 h-16 text-[#E03636]" />,
    title: 'Access Management',
    description: 'Comprehensive access control system ensuring only authorized personnel can access sensitive resources.',
    features: ['Role-based access', 'Multi-factor authentication', 'Session management', 'Audit logging']
  },
  {
    icon: <Server className="w-16 h-16 text-[#E03636]" />,
    title: 'Infrastructure Security',
    description: 'End-to-end infrastructure protection covering networks, servers, and endpoints.',
    features: ['Network monitoring', 'Endpoint protection', 'Vulnerability scanning', 'Patch management']
  },
  {
    icon: <Cloud className="w-16 h-16 text-[#E03636]" />,
    title: 'Cloud Security',
    description: 'Robust cloud security solutions ensuring your data remains protected across all cloud platforms.',
    features: ['Cloud firewall', 'Data encryption', 'Container security', 'Compliance monitoring']
  },
  {
    icon: <Code2 className="w-16 h-16 text-[#E03636]" />,
    title: 'Application Security',
    description: 'Comprehensive application security testing and protection throughout the development lifecycle.',
    features: ['SAST & DAST', 'API security', 'Runtime protection', 'Dependency scanning']
  },
  {
    icon: <Database className="w-16 h-16 text-[#E03636]" />,
    title: 'Data Protection',
    description: 'Advanced data protection solutions ensuring your sensitive information remains secure and compliant.',
    features: ['Data encryption', 'DLP', 'Backup & recovery', 'Compliance management']
  }
];

export function Solutions() {
  const shareUrl = window.location.href;
  const shareTitle = 'Cerberus Solutions | Enterprise Security Solutions';
  const shareDescription = 'Comprehensive enterprise security solutions including threat detection, access management, infrastructure security, and more.';

  return (
    <>
      <SEO 
        title="Enterprise Security Solutions | Cerberus Solutions"
        description="Comprehensive enterprise security solutions including threat detection, access management, infrastructure security, cloud security, and more. Protect your digital assets with Cerberus."
        keywords="enterprise security, threat detection, access management, infrastructure security, cloud security, application security, data protection, network security"
      />
      <div className="min-h-screen bg-[#212121] pt-20">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-24">
          <div className="text-center mb-16">
            <h1 className="text-4xl font-bold text-white mb-4">Security Solutions</h1>
            <p className="text-gray-400 max-w-2xl mx-auto mb-8">
              Comprehensive security solutions designed to protect your digital assets and infrastructure
            </p>
            <div className="flex justify-center mb-8">
              <ShareButtons 
                url={shareUrl}
                title={shareTitle}
                description={shareDescription}
              />
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {solutions.map((solution) => (
              <div key={solution.title} className="bg-black/30 rounded-lg p-8 border border-[#E03636]/20 hover:border-[#E03636]/40 transition-all duration-300">
                <div className="mb-6">{solution.icon}</div>
                <h3 className="text-xl font-semibold text-white mb-4">{solution.title}</h3>
                <p className="text-gray-400 mb-6">{solution.description}</p>
                <ul className="space-y-2">
                  {solution.features.map((feature) => (
                    <li key={feature} className="text-gray-400 flex items-center">
                      <span className="text-[#E03636] mr-2">•</span>
                      {feature}
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}