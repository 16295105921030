import React from 'react';

interface ButtonProps {
  children: React.ReactNode;
  variant?: 'primary' | 'outline';
  onClick?: () => void;
}

export function Button({ children, variant = 'primary', onClick }: ButtonProps) {
  const baseStyles = "px-6 py-3 rounded-lg transition duration-300";
  const variants = {
    primary: "bg-[#E03636] text-white hover:bg-red-700",
    outline: "border border-[#E03636] text-white hover:bg-[#E03636]"
  };

  return (
    <button 
      className={`${baseStyles} ${variants[variant]}`}
      onClick={onClick}
    >
      {children}
    </button>
  );
}