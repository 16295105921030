import React from 'react';
import { Navbar } from './components/layout/Navbar';
import { Hero } from './components/sections/Hero/Hero';
import { Solutions } from './pages/Solutions';
import { Products } from './components/Products';
import { Downloads } from './pages/Downloads';
import { Contact } from './pages/Contact';
import { QuoteRequest } from './pages/QuoteRequest';
import { Footer } from './components/Footer';
import { SEO } from './components/SEO';

function App() {
  const [showMainSite, setShowMainSite] = React.useState(false);
  const [currentPage, setCurrentPage] = React.useState('home');
  const year = new Date().getFullYear();
  
  if (!showMainSite) {
    return (
      <>
        <SEO 
          title="Coming Soon | Cerberus Solutions"
          description="Cerberus Solutions - Advanced cybersecurity and network protection solutions coming soon."
          keywords="cybersecurity, network security, enterprise security, coming soon"
        />
        <div className="min-h-screen bg-[#212121] flex flex-col items-center justify-center relative overflow-hidden">
          <div className="absolute inset-0 bg-grid-pattern opacity-5"></div>
          <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-[500px] h-[500px] bg-[#E03636] rounded-full blur-[120px] opacity-20"></div>
          <div className="relative z-10 text-center px-4">
            <img 
              src="/cerberus-logo.svg" 
              alt="Cerberus Solutions" 
              className="w-full max-w-md mx-auto mb-12 animate-float" 
            />
            <h1 className="text-4xl md:text-6xl font-bold text-white mb-6">
              Coming Soon
            </h1>
            <p className="text-gray-400 text-lg max-w-md mx-auto mb-8">
              Something powerful is brewing. Our new site is under construction.
            </p>
          </div>
          <div className="fixed bottom-0 left-0 right-0 p-6 text-center text-gray-500">
            <p>
              © <span 
                  onClick={() => setShowMainSite(true)}
                  className="cursor-pointer hover:text-[#E03636] transition-colors duration-300"
                  title="Click to view the site"
                >
                  {year}
                </span> Cerberus Solutions
            </p>
          </div>
        </div>
      </>
    );
  }

  const renderPage = () => {
    switch (currentPage) {
      case 'solutions':
        return <Solutions />;
      case 'downloads':
        return <Downloads />;
      case 'contact':
        return <Contact />;
      case 'products':
        return <Products onRequestQuote={() => setCurrentPage('quote')} />;
      case 'quote':
        return <QuoteRequest />;
      default:
        return (
          <>
            <Hero onPageChange={setCurrentPage} />
            <Products onRequestQuote={() => setCurrentPage('quote')} />
          </>
        );
    }
  };

  return (
    <>
      <SEO 
        title="Cerberus Solutions | Network Security Specialists"
        description="Leading provider of enterprise-grade network security solutions and cybersecurity services."
        keywords="network security, cybersecurity, enterprise security, security appliances, threat detection"
      />
      <div className="min-h-screen bg-[#212121]">
        <Navbar onPageChange={setCurrentPage} currentPage={currentPage} />
        {renderPage()}
        <Footer />
        <button
          onClick={() => setShowMainSite(false)}
          className="fixed bottom-4 right-4 bg-[#E03636] text-white px-4 py-2 rounded-lg hover:bg-red-700 transition duration-300"
        >
          Back to Coming Soon
        </button>
      </div>
    </>
  );
}

export default App;