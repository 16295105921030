import React from 'react';

interface NavLinkProps {
  href: string;
  children: React.ReactNode;
  mobile?: boolean;
  onClick?: (e: React.MouseEvent) => void;
  active?: boolean;
}

export function NavLink({ href, children, mobile, onClick, active }: NavLinkProps) {
  const baseStyles = `${active ? 'text-[#E03636]' : 'text-white'} hover:text-[#E03636] px-3 py-2 text-sm font-medium transition-colors duration-300`;
  const mobileStyles = `block ${active ? 'text-[#E03636]' : 'text-white'} hover:text-[#E03636] px-3 py-2 text-base font-medium transition-colors duration-300`;
  
  return (
    <a 
      href={href} 
      className={mobile ? mobileStyles : baseStyles}
      onClick={onClick}
    >
      {children}
    </a>
  );
}