import React from 'react';
import { Shield, Server, Lock } from 'lucide-react';
import { SEO } from './SEO';
import { ShareButtons } from './social/ShareButtons';

interface ProductsProps {
  onRequestQuote?: () => void;
}

const products = [
  {
    name: 'CerberusGuard Pro',
    price: 'Starting at $3,999',
    icon: <Shield className="w-16 h-16 text-[#E03636] mb-6" />,
    description: 'Enterprise-grade network security appliance',
    features: [
      'Next-gen firewall protection',
      'Intrusion Prevention System (IPS)',
      'Advanced threat detection',
      'VPN support with hardware acceleration',
      'Up to 10Gbps throughput',
      '24/7 technical support'
    ]
  },
  {
    name: 'CerberusShield Enterprise',
    price: 'Starting at $6,999',
    icon: <Server className="w-16 h-16 text-[#E03636] mb-6" />,
    description: 'Complete security infrastructure solution',
    features: [
      'Multi-layered security architecture',
      'AI-powered threat analysis',
      'Zero-day attack prevention',
      'Hardware security module (HSM)',
      'Up to 40Gbps throughput',
      'Dedicated support team'
    ]
  },
  {
    name: 'CerberusVault',
    price: 'Starting at $3,999',
    icon: <Lock className="w-16 h-16 text-[#E03636] mb-6" />,
    description: 'Secure data storage and backup appliance',
    features: [
      'Hardware encryption',
      'Secure backup and recovery',
      'Ransomware protection',
      'Multi-site replication',
      'Up to 100TB storage',
      'Priority support response'
    ]
  }
];

export function Products({ onRequestQuote }: ProductsProps) {
  const shareUrl = window.location.href;
  const shareTitle = 'Cerberus Solutions Security Products';
  const shareDescription = 'Discover our enterprise-grade security hardware solutions for comprehensive protection.';

  return (
    <>
      <SEO 
        title="Security Appliances & Products | Cerberus Solutions"
        description="Enterprise-grade security hardware solutions including CerberusGuard Pro, CerberusShield Enterprise, and CerberusVault. Advanced protection for your infrastructure."
        keywords="security appliances, hardware security, CerberusGuard Pro, CerberusShield Enterprise, CerberusVault, network security hardware, enterprise security products"
      />
      <div id="products" className="relative bg-black py-24">
        <div className="absolute inset-0 bg-hex-pattern opacity-5"></div>
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-16">
            <h2 className="text-3xl md:text-4xl font-bold text-white mb-4">Security Appliances</h2>
            <p className="text-gray-400 max-w-2xl mx-auto mb-8">
              Enterprise-grade security hardware solutions for comprehensive protection
            </p>
            <div className="flex justify-center mb-8">
              <ShareButtons 
                url={shareUrl}
                title={shareTitle}
                description={shareDescription}
              />
            </div>
          </div>
          
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
            {products.map((product) => (
              <div 
                key={product.name} 
                className="relative group bg-[#212121] rounded-lg overflow-hidden border border-[#E03636]/20 hover:border-[#E03636]/40 transition-all duration-300"
              >
                <div className="absolute inset-0 bg-gradient-to-br from-[#E03636]/5 to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-300"></div>
                <div className="p-8 relative">
                  {product.icon}
                  <h3 className="text-2xl font-bold text-white mb-2">{product.name}</h3>
                  <p className="text-gray-400 mb-4">{product.description}</p>
                  <p className="text-2xl font-bold text-[#E03636] mb-6">{product.price}</p>
                  <ul className="space-y-3 mb-8">
                    {product.features.map((feature, idx) => (
                      <li key={idx} className="flex items-center text-gray-400">
                        <span className="mr-2 text-[#E03636]">•</span>
                        {feature}
                      </li>
                    ))}
                  </ul>
                  <button 
                    onClick={onRequestQuote}
                    className="w-full bg-[#E03636] text-white py-3 rounded-lg hover:bg-red-700 transition duration-300"
                  >
                    Request Quote
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}