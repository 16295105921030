import React from 'react';

export function Logo() {
  return (
    <div className="flex items-center">
      <a href="/" className="flex-shrink-0">
        <img 
          src="/cerberus-logo-top.svg" 
          alt="Cerberus Solutions" 
          className="h-16 w-auto transition-transform duration-300 hover:scale-105" 
        />
      </a>
    </div>
  );
}