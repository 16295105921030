import React from 'react';
import { Mail, Phone, MapPin } from 'lucide-react';
import ReCAPTCHA from 'react-google-recaptcha';
import { SEO } from '../components/SEO';
import { ShareButtons } from '../components/social/ShareButtons';

export function Contact() {
  const [formData, setFormData] = React.useState({
    name: '',
    email: '',
    businessName: '',
    phone: '',
    message: ''
  });
  const [recaptchaValue, setRecaptchaValue] = React.useState<string | null>(null);

  const shareUrl = 'https://cerberus.demonweb.net/contact';
  const shareTitle = 'Contact Cerberus Solutions';
  const shareDescription = 'Get in touch with our security experts for enterprise-grade cybersecurity solutions.';

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (!recaptchaValue) {
      alert('Please complete the reCAPTCHA verification');
      return;
    }
    console.log('Form data:', formData);
    console.log('reCAPTCHA value:', recaptchaValue);
  };

  return (
    <>
      <SEO 
        title="Contact Cerberus Solutions | Security Experts"
        description="Get in touch with our security experts. We provide enterprise-grade cybersecurity solutions and professional consultation for your security needs."
        keywords="contact security experts, cybersecurity consultation, enterprise security, security solutions, professional security services"
      />
      <div className="min-h-screen bg-[#212121] pt-20">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-24">
          <div className="text-center mb-16">
            <h1 className="text-4xl font-bold text-white mb-4">Get in Touch</h1>
            <p className="text-gray-400 max-w-2xl mx-auto mb-8">
              Have questions about our security solutions? We're here to help.
            </p>
            <div className="flex justify-center mb-8">
              <ShareButtons 
                url={shareUrl}
                title={shareTitle}
                description={shareDescription}
              />
            </div>
          </div>

          <div className="grid grid-cols-1 lg:grid-cols-2 gap-16">
            <div>
              <div className="space-y-6">
                <div className="flex items-center space-x-4 text-gray-400">
                  <Mail className="w-6 h-6 text-[#E03636]" />
                  <span>contact@cerberus.security</span>
                </div>
                <div className="flex items-center space-x-4 text-gray-400">
                  <Phone className="w-6 h-6 text-[#E03636]" />
                  <span>+1 (555) 123-4567</span>
                </div>
                <div className="flex items-center space-x-4 text-gray-400">
                  <MapPin className="w-6 h-6 text-[#E03636]" />
                  <span>123 Security Ave, Cyberspace</span>
                </div>
              </div>
            </div>

            <form onSubmit={handleSubmit} className="bg-black/30 p-8 rounded-lg border border-[#E03636]/20">
              <div className="space-y-6">
                <div>
                  <label htmlFor="name" className="block text-white mb-2">Name *</label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    value={formData.name}
                    onChange={handleInputChange}
                    required
                    className="w-full bg-[#212121] border border-[#E03636]/20 rounded-lg px-4 py-2 text-white focus:outline-none focus:border-[#E03636]"
                  />
                </div>
                
                <div>
                  <label htmlFor="businessName" className="block text-white mb-2">Business Name</label>
                  <input
                    type="text"
                    id="businessName"
                    name="businessName"
                    value={formData.businessName}
                    onChange={handleInputChange}
                    className="w-full bg-[#212121] border border-[#E03636]/20 rounded-lg px-4 py-2 text-white focus:outline-none focus:border-[#E03636]"
                  />
                </div>

                <div>
                  <label htmlFor="email" className="block text-white mb-2">Email *</label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    value={formData.email}
                    onChange={handleInputChange}
                    required
                    className="w-full bg-[#212121] border border-[#E03636]/20 rounded-lg px-4 py-2 text-white focus:outline-none focus:border-[#E03636]"
                  />
                </div>

                <div>
                  <label htmlFor="phone" className="block text-white mb-2">Phone Number</label>
                  <input
                    type="tel"
                    id="phone"
                    name="phone"
                    value={formData.phone}
                    onChange={handleInputChange}
                    pattern="[0-9\-\+\(\)\s]+"
                    title="Please enter a valid phone number"
                    className="w-full bg-[#212121] border border-[#E03636]/20 rounded-lg px-4 py-2 text-white focus:outline-none focus:border-[#E03636]"
                  />
                </div>

                <div>
                  <label htmlFor="message" className="block text-white mb-2">Message *</label>
                  <textarea
                    id="message"
                    name="message"
                    value={formData.message}
                    onChange={handleInputChange}
                    required
                    rows={4}
                    className="w-full bg-[#212121] border border-[#E03636]/20 rounded-lg px-4 py-2 text-white focus:outline-none focus:border-[#E03636]"
                  ></textarea>
                </div>

                <div className="flex justify-center">
                  <ReCAPTCHA
                    sitekey="YOUR_RECAPTCHA_SITE_KEY"
                    onChange={(value) => setRecaptchaValue(value)}
                    theme="dark"
                  />
                </div>

                <button
                  type="submit"
                  className="w-full bg-[#E03636] text-white py-3 rounded-lg hover:bg-red-700 transition duration-300 disabled:opacity-50 disabled:cursor-not-allowed"
                  disabled={!recaptchaValue}
                >
                  Send Message
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}