import React from 'react';
import { Github } from 'lucide-react';
import { SEO } from '../components/SEO';
import { ShareButtons } from '../components/social/ShareButtons';

interface Script {
  name: string;
  description: string;
  githubUrl: string;
}

const scripts: Script[] = [
  {
    name: 'NetScope',
    description: 'System connections and process monitoring',
    githubUrl: 'https://github.com/yodabytz/netscope'
  },
  {
    name: 'ModSentry',
    description: 'Real-time monitoring of ModSecurity logs',
    githubUrl: 'https://github.com/yodabytz/modsentry'
  },
  {
    name: 'SwapWatch',
    description: 'Memory swap monitoring and optimization tool',
    githubUrl: 'https://github.com/yodabytz/swapwatch'
  },
  {
    name: 'SecuNX',
    description: 'Web Application Firewall to block bad actors.',
    githubUrl: 'https://github.com/yodabytz/secunx'
  },
  {
    name: 'Fail2Many',
    description: 'Permantly ban repeat offenders for fail2ban',
    githubUrl: 'https://github.com/yodabytz/fail2many'
  },
  {
    name: 'DiffAnywhere',
    description: 'Universal diff tool for code analysis',
    githubUrl: 'https://github.com/yodabytz/diffanywhere'
  }
];

export function Downloads() {
  const shareUrl = 'https://cerberus.demonweb.net/downloads';
  const shareTitle = 'Cerberus Security Tools & Downloads';
  const shareDescription = 'Download our open-source security tools to enhance your system protection.';

  return (
    <>
      <SEO 
        title="Security Tools & Downloads | Cerberus Solutions"
        description="Download our open-source security tools including NetScope, ModSentry, SwapWatch, SecuNX, Fail2Many, and DiffAnywhere. Enhance your system's protection with our trusted tools."
        keywords="security tools, open source security, NetScope, ModSentry, SwapWatch, SecuNX, Fail2Many, DiffAnywhere, system protection, network monitoring"
      />
      <div className="min-h-screen bg-[#212121] pt-20">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-24">
          <div className="text-center mb-16">
            <h1 className="text-4xl font-bold text-white mb-4">Security Tools</h1>
            <p className="text-gray-400 max-w-2xl mx-auto mb-8">
              Download our open-source security tools and enhance your system's protection
            </p>
            <div className="flex justify-center mb-8">
              <ShareButtons 
                url={shareUrl}
                title={shareTitle}
                description={shareDescription}
              />
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {scripts.map((script) => (
              <div key={script.name} className="bg-black/30 rounded-lg p-6 border border-[#E03636]/20 hover:border-[#E03636]/40 transition-all duration-300">
                <h3 className="text-xl font-semibold text-white mb-2">{script.name}</h3>
                <p className="text-gray-400 mb-4">{script.description}</p>
                <a
                  href={script.githubUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="inline-flex items-center space-x-2 text-[#E03636] hover:text-red-400 transition-colors duration-300"
                >
                  <Github className="w-5 h-5" />
                  <span>View on GitHub</span>
                </a>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}