import React from 'react';
import { NavLink } from '../ui/NavLink';

interface MobileMenuProps {
  items: Array<{ href: string; label: string; page: string; }>;
  onNavClick: (page: string, e: React.MouseEvent) => void;
  currentPage: string;
}

export function MobileMenu({ items, onNavClick, currentPage }: MobileMenuProps) {
  return (
    <div className="md:hidden bg-[#212121]/95 backdrop-blur-sm">
      <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
        {items.map((item) => (
          <NavLink 
            key={item.href} 
            href={item.href} 
            mobile 
            onClick={(e) => onNavClick(item.page, e)}
            active={currentPage === item.page}
          >
            {item.label}
          </NavLink>
        ))}
      </div>
    </div>
  );
}