import React from 'react';
import { Facebook, Twitter, Linkedin, Link, Instagram, Send } from 'lucide-react';

interface ShareButtonsProps {
  url: string;
  title: string;
  description?: string;
}

export function ShareButtons({ url, title, description }: ShareButtonsProps) {
  const handleCopyLink = async () => {
    try {
      await navigator.clipboard.writeText(url);
      alert('Link copied to clipboard!');
    } catch (error) {
      console.error('Error copying to clipboard:', error);
    }
  };

  const shareButtons = [
    {
      name: 'Facebook',
      icon: <Facebook className="w-5 h-5" />,
      onClick: () => {
        window.open(
          `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}`,
          '_blank',
          'width=600,height=400'
        );
      },
    },
    {
      name: 'Twitter',
      icon: <Twitter className="w-5 h-5" />,
      onClick: () => {
        window.open(
          `https://twitter.com/intent/tweet?text=${encodeURIComponent(title)}&url=${encodeURIComponent(url)}`,
          '_blank',
          'width=600,height=400'
        );
      },
    },
    {
      name: 'LinkedIn',
      icon: <Linkedin className="w-5 h-5" />,
      onClick: () => {
        window.open(
          `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(url)}&title=${encodeURIComponent(title)}&summary=${encodeURIComponent(description || '')}`,
          '_blank',
          'width=600,height=400'
        );
      },
    },
    {
      name: 'Instagram',
      icon: <Instagram className="w-5 h-5" />,
      onClick: () => {
        // Instagram doesn't have a direct share URL, so we'll open Instagram with a helpful message
        alert('To share on Instagram:\n1. Take a screenshot\n2. Open Instagram\n3. Create a new post with the screenshot\n4. Copy and paste the following in your caption:\n\n' + title + '\n' + url);
      },
    },
    {
      name: 'Telegram',
      icon: <Send className="w-5 h-5" />,
      onClick: () => {
        window.open(
          `https://t.me/share/url?url=${encodeURIComponent(url)}&text=${encodeURIComponent(title)}`,
          '_blank',
          'width=600,height=400'
        );
      },
    },
    {
      name: 'Copy Link',
      icon: <Link className="w-5 h-5" />,
      onClick: handleCopyLink,
    },
  ];

  return (
    <div className="flex items-center space-x-4">
      <span className="text-gray-400 text-sm">Share:</span>
      <div className="flex space-x-3">
        {shareButtons.map((button) => (
          <button
            key={button.name}
            onClick={button.onClick}
            className="text-gray-400 hover:text-[#E03636] transition-colors duration-300 transform hover:scale-110"
            aria-label={`Share on ${button.name}`}
          >
            {button.icon}
          </button>
        ))}
      </div>
    </div>
  );
}